<template>
  <div class="Header0">
    <div class="login-header" :class="clientWidth > 768 ? 'flex3' : 'flex0'">
      <img class="logo0" src="@/assets/PC/header/logo_slices/logo0.png"/>
      <img v-if="clientWidth > 768" class="logo0" src="@/assets/PC/header/logo_slices/logo_right0.png"/>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loginHeaders",
  data(){
    return{}
  },
  computed: {
    clientWidth () {
      return this.$store.state.clientWidth;
    }
  }
}
</script>

<style lang="scss" scoped>
$mobil_base: 750;
@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}
// ?PC
.Header0{ width: 100%; height: 83px; background: linear-gradient(90deg, #430f5c 0%, #5f1593 100%); box-shadow: 0px 2px 27px 2px rgba(127, 123, 129, 0.18) }
.login-header{max-width: 1200px; height: 83px; margin: 0 auto; position: relative;}
.logo{position: absolute; left: 0;top: 16px; }
// ?mobile
@media (max-width: 750px) {
  .Header0,.login-header{height: vw(100)}
  .logo0{width: vw(100)}
  .logo{display: none;}
}
</style>

<style>
@media (max-width: 750px) {
  .login-header .logo {display: none!important;}
}
  
</style>