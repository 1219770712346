<template>
	<div class="set">
		<loginHeader></loginHeader>
		<div class="login-body">
			<div class="login-detail">
				<div class="login-detail-title">
					<div class="login-detail-title-item login-detail-title-item-active">设置密码</div>
				</div>
				<div class="login-detail-form">
					<el-form class="loginForm" :model="form" ref="Forms" :rules="rules" status-icon>
						<el-form-item prop="newPwd">
							<el-input v-model="form.newPwd" type="password" placeholder="请输入新密码"></el-input>
						</el-form-item>
						<el-form-item prop="Pwd">
							<el-input v-model="form.Pwd" type="password" placeholder="请输入确认新密码"></el-input>
						</el-form-item>
						<el-form-item class="tc">
							<el-button type="primary" :loading="loading" @click="set">确认</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import loginHeader from '@/components/loginHeader.vue';
	import { resetPwd } from "@/api/register.js";
	import { dataState } from "@/api/all.js";
	
	export default {
		components: {
			loginHeader
		},
		name: "set",
		data() {
			return {
				errorMsg: '', //错误信息
				loading: false, //loading
				windowWidth: document.documentElement.clientWidth,
				form: {
					newPwd: "",
					Pwd: "",
					userId: ''
				},
				rules: {
					newPwd: [{
							required: true,
							message: "请输入新密码",
							trigger: "blur"
					}],
					Pwd: [{
							required: true,
							message: "请输入确认新密码",
							trigger: "blur"
					}],
				},
			};
		},
		created(){
			this.form.userId = this.$route.query.useId
		},
		mounted() {
			//监听屏幕宽度
			const that = this;
			window.onresize = () => {
				return (() => {
					that.windowWidth = document.documentElement.clientWidth;
				})()
			};

			// 数据统计
			const data ={
				"visitType": 1,
				"gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
				"url": window.location.href,
				"pageUrl": "Set",
				"relationId": ""
			}
			dataState(data).then(res => {
				sessionStorage.setItem('gid',res.data);
			})
		},
		methods: {
			set() {
				this.$refs['Forms'].validate((valid) => {
					if (valid) {
						if(this.form.newPwd != this.form.Pwd){
							this.$toast({
								message: '两次输入的密码不一致！',
								duration: 1000
							})
							return
						}
						this.loading = true;
						resetPwd(this.form).then(res => {
							if (res.status == 1) {
								this.$toast.success({
									message: '登录成功！',
									duration: 1000
								})
								setTimeout(() => {
									this.$router.push({
										path: '/login'
									});
								}, 1000);
							} else {
								this.loading = false;
								this.errorMsg = null;
								this.$toast({
									message: res.message,
									duration: 2000
								})
								this.$nextTick(() => {
									this.errorMsg = res.message
								})
							}
						})
					} else {
						this.loading = false;
						return false;
					}
				});
			}
		},
	};
</script>

<style lang="scss" scoped>
	$mobil_base: 750;

	@function vw($true) {
		@return ($true / $mobil_base) * 100vw;
	}

	@media (min-width: 750px) {
		.set {
			min-height: 500px;
			height: 100vh;
			background: #f7f7f7;

			.login-body {
				width: 100%;
				height: calc(100% - 83px);
				display: flex;
				justify-content: center;
				align-items: center;

				.login-detail {
					width: 448px;
					height: 376px;
					margin: 0 auto;
					box-shadow: 0 2px 4px 0 rgb(226, 224, 224), 0 0 6px 0 rgb(226, 224, 224);

					.login-detail-title {
						height: 78px;
						display: flex;

						.login-detail-title-item {
							width: 100%;
							font-size: 24px;
							font-weight: 400;
							line-height: 78px;
							text-align: center;
							border: 1px #f7f7f7 solid;
							cursor: pointer;
						}

						.login-detail-title-item-active {
							background: #fff;
							border-bottom: 1px #fff solid;
							color: #6b18a8;
						}
					}
					
					.notice_area{
						background: #fff;
						.notice{
							width: 280px;
							padding-top: 10px;
							margin: 0 auto;
							.red{
								color: #f60000;
							}
						}
					}

					.login-detail-form {
						height: calc(100% - 126px);
						background: #fff;

						.loginForm {
							width: 280px;
							padding-top: 30px;
							margin: 0 auto;
						}
					}
				}
			}

			.login {
				.el-tabs__item {
					width: 50%;
					box-sizing: border-box;
				}

				.login-verification {
					padding-top: 30px;
					width: 280px;
					margin: 0 auto;
				}

				.ph-login {
					background: #6b18a8;
					width: 100%;
					color: #fff;
				}

			}
		}
	}

	@media (max-width: 750px) {
		* {
			font-size: vw(24);
		}

		.set {
			min-height: 100vh;
			background: #f7f7f7;

			.login-body {
				width: 100%;
				min-height: calc(100vh - 16vw);
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.login-detail {
				width: vw(670);
				height: vw(590);
				margin: 0 auto;
				overflow: hidden;
				box-shadow: 0 2px 4px 0 rgb(226, 224, 224), 0 0 6px 0 rgb(226, 224, 224);
			}

			.login-detail-title {
				height: vw(80);
				display: flex;

				.login-detail-title-item {
					font-size: vw(29);
					font-weight: 400;
					line-height: vw(80);
					text-align: center;
					border: 1px #f7f7f7 solid;
					cursor: pointer;
				}

				.login-detail-title-item-active {
					background: #fff;
					border-bottom: 1px #fff solid;
					color: #6b18a8;
				}
			}

			.login-detail-form {
				height: calc(100% - 10.6667vw);
				padding-top: vw(38);
				background: #fff;

				.loginForm {
					width: vw(558);
					margin: 0 auto;
				}
			}
		}
	}
</style>
